import asyncComponent from "../../modules/custom/async-component";

const Dashboard = asyncComponent(() => import('../../pages/dashboard/Dashboard'));
const DailyReport = asyncComponent(() => import('../../pages/daily_report'));
const PurchaseExpense = asyncComponent(() => import('../../pages/purchase_expense'));
const LotterySettlement = asyncComponent(() => import('../../pages/lottery/lottery-settlement'));
const ManageDepartments = asyncComponent(() => import('../../pages/settings/manage-department'));
const StoreOtherIncomeType = asyncComponent(() => import('../../pages/settings/store-other-income-type'));
const StoreDueDays = asyncComponent(() => import('../../pages/settings/store-due-days'));
const InstantBookSettlement = asyncComponent(() => import('../../pages/lottery/lottery-settlement-instant'));
const userPermission = asyncComponent(() => import('../../pages/settings/user-permission'));
const BookMovement = asyncComponent(() => import('../../pages/lottery/book-movement'));
const VendingBookMovement = asyncComponent(() => import('../../pages/lottery/vending-book-movement'));
const LotteryHold = asyncComponent(() => import('../../pages/lottery/lottery-hold'));
const UserRoles = asyncComponent(() => import('../../pages/settings/user-roles'));
const ManageUser = asyncComponent(() => import('../../pages/settings/manage-user'));
const ManageBank = asyncComponent(() => import('../../pages/settings/manage-bank'));
const StepReport = asyncComponent(() => import('../../pages/step-report/step-report'));
const GasInvoice = asyncComponent(() => import('../../pages/gas/gas-invoice'));
const GasTaxSettings = asyncComponent(() => import('../../pages/gas/gas-tax-settings'));
const GasSettings = asyncComponent(() => import('../../pages/gas/gas-settings'));
const Payroll = asyncComponent(() => import('../../pages/payroll/payroll'));
const Settings = asyncComponent(() => import('../../pages/settings'));
const StoreVendor = asyncComponent(() => import('../../pages/settings/store-vendor'));
const ExpenseType = asyncComponent(() => import('../../pages/settings/store-expense-type'));
const LotteryInventory = asyncComponent(() => import('../../pages/lottery/lottery-inventory'));
const GeneralLedger = asyncComponent(() => import('../../pages/dashboard/Dashboard'));
const AtmDeposits = asyncComponent(() => import('../../pages/ledger/atm-deposit'));
const BankDeposits = asyncComponent(() => import('../../pages/ledger/bank-deposit'));
const OwnerDistribution = asyncComponent(() => import('../../pages/ledger/owner-distribution'));
const OtherIncome = asyncComponent(() => import('../../pages/ledger/other-income'));
const SalesTax = asyncComponent(() => import('../../pages/ledger/sales-tax'));
const BankLedger = asyncComponent(() => import('../../pages/ledger/bank-ledger'));
const CashLedger = asyncComponent(() => import('../../pages/ledger/cash-ledger'));

const BusinessReports = asyncComponent(() => import('../../pages/reports/business-analytics'));
const BusinessAnalytics = asyncComponent(() => import('../../pages/reports/business-analytics'));
const BusinessMonthly = asyncComponent(() => import('../../pages/reports/business-monthly'));
const PurchaseMonthly = asyncComponent(() => import('../../pages/reports/purchase-monthly'));
const ExpenseMonthly = asyncComponent(() => import('../../pages/reports/expense-monthly'));
const PayrollMonthly = asyncComponent(() => import('../../pages/reports/payroll-monthly'));
const LotteryReports = asyncComponent(() => import('../../pages/reports/lottery-analytics'));
const LotteryAnalytics = asyncComponent(() => import('../../pages/reports/lottery-analytics'));
const ProfitLossReport = asyncComponent(() => import('../../pages/reports/profit-loss-report'));
const LotteryMonthly = asyncComponent(() => import('../../pages/reports/lottery-monthly'));
const GasReport = asyncComponent(() => import('../../pages/reports/gas-report'));
const GasAnalytics = asyncComponent(() => import('../../pages/reports/gas-analytics'));
const GasDifferential = asyncComponent(() => import('../../pages/reports/gas-difference'));
const GasCommissions = asyncComponent(() => import('../../pages/reports/gas-commissions'));
const HouseCharges = asyncComponent(() => import('../../pages/gas/house-charges'));

const StoreOtherVendors = asyncComponent(() => import('../../pages/settings/store-other-vendors'));
const StoreProfile = asyncComponent(() => import('../../pages/settings/store-profile'));
const LotteryGames = asyncComponent(() => import('../../pages/lottery/lottery-games'));
const LotteryGamesStore = asyncComponent(() => import('../../pages/lottery/lottery-games-store'));
const WeeklySettlementAdmin = asyncComponent(() => import('../../pages/lottery/weekly/create.weekly.form'));
const Configuration = asyncComponent(() => import('../../pages/configurations/configuration'));
const EmailTemplates = asyncComponent(() => import('../../pages/emailtemplates/email-templates'));
const ChangePassword = asyncComponent(() => import('../../pages/settings/change-password'));
const StoreNotes = asyncComponent(() => import('../../pages/dashboard/StoreNotes'));
const ActivityLog =  asyncComponent(()=>import('../../pages/activity/Activity-log'));
const SupportLog =  asyncComponent(()=>import('../../pages/support_log/SupportLog'));
const MoveStore = asyncComponent(()=>import('../../pages/move_store/MoveStore'));
const StoreGraph = asyncComponent(()=> import('../../pages/storegraph/StoreGraph'));
const Transactions = asyncComponent(()=> import('../../pages/transactions/Transactions'));

export const lottery_routes = [
    {
        id: 1,
        name: "Lottery Inventory",
        title: "Hisably | Lottery Inventory",
        path: "/inventory",
        icon: 'fa-ticket-alt',
        component: LotteryInventory,
        exact: true,
        permissions: ['lottery_inventories.page'],
        parent_path: '/lottery',
    },
    {
        id: 2,
        name: "Weekly Invoice Settlement",
        title: "Hisably | Weekly Invoice Settlement",
        path: "/lottery-settlement",
        icon: 'fa-ticket-alt',
        exact: true,
        hidden: true,
        hidden: true,
        permissions: ['weekly_invoice_settlements.page'],
        component: LotterySettlement,
        parent_path: '/lottery',
    },
    {
        id: 3,
        name: "Instant Book Settlement",
        title: "Hisably | Instant Book Settlement",
        path: "/lottery-settlement-instant",
        icon: 'fa-ticket-alt',
        exact: true,
        hidden: true,
        permissions: ['instant_book_settlements.page'],
        component: InstantBookSettlement,
        parent_path: '/lottery',
    },
    {
        id: 4,
        name: "Book Movement",
        title: "Hisably | Book Movement",
        path: "/book-movement",
        icon: 'fa-ticket-alt',
        exact: true,
        hidden: true,
        permissions: ['book_movements.page'],
        component: BookMovement,
        parent_path: '/lottery',
    },
    {
        id: 5,
        name: "Vending Book Movement",
        title: "Hisably | Vending Book Movement",
        path: "/vending-book-movement",
        icon: 'fa-ticket-alt',
        exact: true,
        permissions: ['vending_book_movements.page'],
        component: VendingBookMovement,
        hidden: true,
        parent_path: '/lottery',
    },
    {
        id: 6,
        name: "Lottery Book On Hold",
        title: "Hisably | Lottery Book On Hold",
        path: "/lottery-hold",
        icon: 'fa-ticket-alt',
        exact: true,
        permissions: ['lottery_books_on_hold.page'],
        component: LotteryHold,
        hidden: true,
        parent_path: '/lottery',
    },
    {
        id: 8,
        name: "Manage Games",
        title: "Hisably | Manage Games",
        path: "/lottery-games-store",
        icon: 'fa-ticket-alt',
        exact: true,
        permissions: ['lottery_games_store.page'],
        component: LotteryGamesStore,
        hidden: true,
        parent_path: '/lottery',
    }
];

export const gas_invoice = [
    {
        id: 2,
        name: "Gas Invoice",
        title: "Hisably | Gas Invoice",
        path: "/gas-invoice",
        icon: 'fa-gas-pump',
        exact: true,
        settings_key: 'isGasReportDailyReport',
        permissions: ['gas_invoices.page'],
        component: GasInvoice,
        parent_path: '/gas',
    },
    {
        id: 5,
        name: "House Charges",
        title: "Hisably | House Charges",
        path: "/house-charges",
        icon: 'fa-gas-pump',
        exact: true,
        permissions: ['house_charges.page'],
        component: HouseCharges,
        hidden: true,
        settings_key: 'isGasReportDailyReport',
        parent_path: '/gas',
    },
    {
        id: 7,
        name: "Gas Tax Settings",
        title: "Hisably | Gas Tax Settings",
        path: "/gas-tax-settings",
        icon: 'fa-gas-pump',
        exact: true,
        permissions: ['gas_tax_settings.page'],
        component: GasTaxSettings,
        hidden: true,
        settings_key: 'isGasReportDailyReport',
        parent_path: '/gas',
    },
    {
        id: 6,
        name: "Gas Settings",
        title: "Hisably | Gas Settings",
        path: "/gas-settings",
        icon: 'fa-gas-pump',
        exact: true,
        permissions: ['gas_settings.page'],
        component: GasSettings,
        hidden: true,
        settings_key: 'isGasReportDailyReport',
        parent_path: '/gas',
    },
];

export const gas_reports = [
    {
        id: 1,
        name: "Gas Daily Report",
        title: "Hisably | Gas Daily Report",
        path: "/gas-report",
        icon: '',
        component: GasReport,
        permissions: ['gas_differentials.page'],
        exact: true,
        settings_key: 'isGasReportDailyReport',
        parent_path: '/reports',
    },
    {
        id: 3,
        name: "Gas Analytics",
        title: "Hisably | Gas Analytics",
        path: "/gas-analytics",
        icon: 'fa-chart-bar',
        component: GasAnalytics,
        permissions: ['gas_differentials.page'],
        exact: true,
        settings_key: 'isGasReportDailyReport',
        hidden: true,
        parent_path: '/reports',
    },
    {
        id: 4,
        name: "Gas Differential",
        title: "Hisably | Gas Differential",
        path: "/gas-difference",
        icon: 'fa-gas-pump',
        exact: true,
        permissions: ['gas_differentials.page'],
        component: GasDifferential,
        settings_key: 'isGasReportDailyReport',
        hidden: true,
        parent_path: '/reports',
    },
    {
        id: 5,
        name: "Gas Commissions",
        title: "Hisably | Gas Commissions",
        path: "/gas-commissions",
        icon: 'fa-gas-pump',
        exact: true,
        permissions: ['gas_differentials.page'],
        component: GasCommissions,
        settings_key: 'isGasReportDailyReport',
        hidden: true,
        parent_path: '/reports',
    },
];

export const ledgers = [
    {
        id: 1,
        name: "ATM Deposit",
        title: "Hisably | ATM Deposit",
        path: "/atm",
        icon: 'fa-university',
        component: AtmDeposits,
        permissions: ['atm_deposit.page'],
        exact: true,
        parent_path: '/ledger',
    },
    {
        id: 2,
        name: "Bank Deposit",
        title: "Hisably | Bank Deposit",
        path: "/bank-deposit",
        icon: 'fa-university',
        component: BankDeposits,
        permissions: ['bank_deposit.page'],
        exact: true,
        hidden: true,
        parent_path: '/ledger',
    },
    {
        id: 3,
        name: "Owner Distributions",
        title: "Hisably | Owner Distribution",
        path: "/owner-distributions",
        icon: 'fa-university',
        component: OwnerDistribution,
        permissions: ['owner_distributions.page'],
        exact: true,
        hidden: true,
        parent_path: '/ledger',
    },
    {
        id: 4,
        name: "Other Income",
        title: "Hisably | Other Income",
        path: "/other-income",
        icon: 'fa-university',
        component: OtherIncome,
        permissions: ['other_income.page'],
        exact: true,
        hidden: true,
        parent_path: '/ledger',
    },
    {
        id: 5,
        name: "Sales Tax",
        title: "Hisably | Sales Tax",
        path: "/sales-tax",
        icon: 'fa-university',
        component: SalesTax,
        permissions: ['sales_tax.page'],
        exact: true,
        hidden: true,
        parent_path: '/ledger',
    },
    {
        id: 6,
        name: "Bank Ledger",
        title: "Hisably | Bank Ledger",
        path: "/bank-ledger",
        icon: 'fa-university',
        component: BankLedger,
        permissions: ['sales_tax.page'],
        exact: true,
        hidden: true,
        parent_path: '/ledger',
    },
    {
        id: 7,
        name: "Cash Ledger",
        title: "Hisably | Cash Ledger",
        path: "/cash-ledger",
        icon: 'fa-university',
        component: CashLedger,
        permissions: ['sales_tax.page'],
        exact: true,
        hidden: true,
        parent_path: '/ledger',
    }
];

export const business_reports = [
    {
        id: 1,
        name: "Business Analytics",
        title: "Hisably | Business Analytics",
        path: "/business-analytics",
        icon: '',
        component: BusinessAnalytics,
        permissions: ['business_analytics.page'],
        exact: true,
        parent_path: '/reports',
    },
    {
        id: 2,
        name: "Business Monthly",
        title: "Hisably | Business Monthly",
        path: "/business-monthly",
        icon: 'fa-chart-bar',
        component: BusinessMonthly,
        permissions: ['business_monthly.page'],
        exact: true,
        hidden: true,
        parent_path: '/reports',
    },
    {
        id: 3,
        name: "Purchase Monthly",
        title: "Hisably | Purchase Monthly",
        path: "/purchase-monthly",
        icon: 'fa-chart-bar',
        component: PurchaseMonthly,
        permissions: ['purchase_monthly.page'],
        exact: true,
        hidden: true,
        parent_path: '/reports',
    },
    {
        id: 4,
        name: "Expense Monthly",
        title: "Hisably | Expense Monthly",
        path: "/expense-monthly",
        icon: 'fa-chart-bar',
        component: ExpenseMonthly,
        permissions: ['expense_monthly.page'],
        exact: true,
        hidden: true,
        parent_path: '/reports',
    },
    {
        id: 5,
        name: "Payroll Monthly",
        title: "Hisably | Payroll Monthly",
        path: "/payroll-monthly",
        icon: 'fa-chart-bar',
        component: PayrollMonthly,
        permissions: ['payroll_monthly.page'],
        exact: true,
        hidden: true,
        parent_path: '/reports',
    },
];

export const lottery_reports = [
    {
        id: 6,
        name: "Lottery Analytics",
        title: "Hisably | Lottery Analytics",
        path: "/lottery-analytics",
        icon: '',
        component: LotteryAnalytics,
        permissions: ['lottery_analytics.page'],
        exact: true,
        parent_path: '/reports',
    },
    {
        id: 7,
        name: "Lottery Monthly",
        title: "Hisably | Lottery Monthly",
        path: "/lottery-monthly",
        icon: 'fa-chart-bar',
        component: LotteryMonthly,
        exact: true,
        hidden: true,
        parent_path: '/reports',
    }
];

export const profit_loss_report=[
    {
        id: 8,
        name: "Profit & Loss Report",
        title: "Hisably | Profit & Loss Report",
        path: "/profit-loss-report",
        icon: '',
        component: ProfitLossReport,
        permissions: ['lottery_analytics.page'],
        exact: true,
        parent_path: '/reports',
    },
]


export const top_business_reports = [
    {
        id: 1,
        name: "Business Analytics",
        title: "Hisably | Business Analytics",
        path: "/business-analytics",
        icon: 'fa-chart-bar',
        component: BusinessAnalytics,
        permissions: ['business_analytics.page'],
        exact: true,
        parent_path: '/reports',
    },
    {
        id: 2,
        name: "Business Monthly",
        title: "Hisably | Business Monthly",
        path: "/business-monthly",
        icon: 'fa-chart-bar',
        component: BusinessMonthly,
        permissions: ['business_monthly.page'],
        exact: true,
        hidden: true,
        parent_path: '/reports',
    },
    {
        id: 3,
        name: "Purchase Monthly",
        title: "Hisably | Purchase Monthly",
        path: "/purchase-monthly",
        icon: 'fa-chart-bar',
        component: PurchaseMonthly,
        permissions: ['purchase_monthly.page'],
        exact: true,
        hidden: true,
        parent_path: '/reports',
    },
    {
        id: 4,
        name: "Expense Monthly",
        title: "Hisably | Expense Monthly",
        path: "/expense-monthly",
        icon: 'fa-chart-bar',
        component: ExpenseMonthly,
        permissions: ['expense_monthly.page'],
        exact: true,
        hidden: true,
        parent_path: '/reports',
    },
    {
        id: 5,
        name: "Payroll Monthly",
        title: "Hisably | Payroll Monthly",
        path: "/payroll-monthly",
        icon: 'fa-chart-bar',
        component: PayrollMonthly,
        permissions: ['payroll_monthly.page'],
        exact: true,
        hidden: true,
        parent_path: '/reports',
    },
];

// merging business_reports, lottery reports and gas reports array
business_reports.push.apply(business_reports, lottery_reports);
business_reports.push.apply(business_reports, profit_loss_report);
business_reports.push.apply(business_reports, gas_reports);
const busineess_and_lottery_reports = business_reports;

export const settings = [
    {
        id: 1,
        name: "Daily Report Settings",
        title: "Hisably | Manage Settings",
        path: "/store-settings",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['store_settings.page'],
        component: Settings,
        parent_path: '/settings',
    },
    {
        id: 10,
        name: "Store Profile",
        title: "Hisably | Store Profile",
        path: "/store-profile",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['store_profile.page'],
        component: StoreProfile,
        parent_path: '/settings',
        hidden: true,
        // top_hidden: true
    },
    {
        id: 6,
        name: "Manage User",
        title: "Hisably | Manage User",
        path: "/manage-user",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['store_users.page'],
        component: ManageUser,
        hidden: true,
        parent_path: '/settings',
    },
    {
        id: 8,
        name: "Manage Departments",
        title: "Hisably | Manage Departments",
        path: "/manage-department",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['store_departments.page'],
        component: ManageDepartments,
        hidden: true,
        parent_path: '/settings',
    },
    {
        id: 2,
        name: "Manage Vendor",
        title: "Hisably | Manage Vendor",
        path: "/store-vendor",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['store_vendors.page'],
        component: StoreVendor,
        hidden: true,
        parent_path: '/settings',
    },
    {
        id: 6,
        name: "Manage Expense Type",
        title: "Hisably | Manage Expense Type",
        path: "/store-expense-type",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['store_vendors.page'],
        component: ExpenseType,
        hidden: true,
        parent_path: '/settings',
    },
    // {
    //     id: 3,
    //     name: "Store Other Vendor",
    //     title: "Hisably | Store Other Vendor",
    //     path: "/store-other-vendors",
    //     icon: 'fa-cogs',
    //     exact: true,
    //     permissions: ['store_other_vendors.page'],
    //     component: StoreOtherVendors,
    //     hidden: true,
    //     parent_path: '/settings',
    // },
    {
        id: 9,
        name: "Other Income",
        title: "Hisably | Other Income",
        path: "/store-other-income-type",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['store_other_income_type.page'],
        component: StoreOtherIncomeType,
        hidden: true,
        parent_path: '/settings',
    },
    // {
    //     id: 9,
    //     name: "Due Days Options",
    //     title: "Hisably | Due Days Options",
    //     path: "/store-due-days",
    //     icon: 'fa-cogs',
    //     exact: true,
    //     permissions: ['store_due_days.page'],
    //     component: StoreDueDays,
    //     hidden: true,
    //     parent_path: '/settings',
    // },
    {
        id: 4,
        name: "User Permission",
        title: "Hisably | User Permission",
        path: "/user-permission",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['permissions.page'],
        component: userPermission,
        hidden: true,
        super_admin_show: true,
        parent_path: '/settings',
    },
    {
        id: 5,
        name: "User Roles",
        title: "Hisably | User Roles",
        path: "/user-roles",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['user_roles.page'],
        component: UserRoles,
        hidden: true,
        super_admin_show: true,
        parent_path: '/settings',
    },
    {
        id: 5,
        name: "Configuration",
        title: "Hisably | Configuration",
        path: "/configuration",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['user_roles.page'],
        component: Configuration,
        hidden: true,
       /*  top_hidden: true, */
        super_admin_show: true,
        parent_path: '/settings',
    },
    // {
    //     id: 7,
    //     name: "Manage Bank",
    //     title: "Hisably | Manage Bank",
    //     path: "/manage-bank",
    //     icon: 'fa-cogs',
    //     exact: true,
    //     permissions: ['store_banks.page'],
    //     component: ManageBank,
    //     hidden: true,
    //     parent_path: '/settings',
    // },
];

const routes = [
    {
        id: 1,
        name: "Dashboard",
        title: "Hisably | Dashboard",
        path: "/",
        icon: 'fa-tachometer-alt',
        exact: true,
        component: Dashboard,
        permissions: ['dashboard'],
    },
    {
        id: 2,
        name: "Daily Report",
        title: "Hisably | Daily Report",
        path: "/report",
        icon: 'fa-file',
        exact: true,
        child: [
            {
                id: 1,
                name: "Full Report",
                title: "Hisably | Full Report",
                path: "/daily_report",
                icon: '',
                exact: true,
                component: DailyReport,
                permissions: ['daily_report.page'],
            },
            {
                id: 2,
                name: "Step Report",
                title: "Hisably | Step Report",
                path: "/step-report",
                icon: '',
                exact: true,
                component: StepReport,
                permissions: ['step_report.page'],
            }
        ]
    },
    {
        id: 3,
        name: "Expense",
        title: "Hisably | Expense",
        path: "/expense",
        icon: 'fa-table',
        exact: true,
        permissions: ['expense.page'],
        component: PurchaseExpense,
    },
    {
        id: 4,
        name: "Purchase & Payments",
        title: "Hisably | Purchase & Payments",
        path: "/purchase",
        icon: 'fa-list-alt',
        exact: true,
        permissions: ['purchase_&_payments.page'],
        component: PurchaseExpense,
    },
    {
        id: 5,
        name: "Payroll",
        title: "Hisably | Payroll",
        path: "/payroll",
        icon: 'fa-dollar-sign',
        exact: true,
        permissions: ['payroll.page'],
        component: Payroll,
    },
    {
        id: 6,
        name: "Lottery",
        title: "Hisably | Lottery",
        path: "/lottery",
        icon: 'fa-ticket-alt',
        exact: true,
        component: LotteryInventory,
        child: lottery_routes,
    },
    {
        id: 7,
        name: "Gas",
        title: "Hisably | Gas",
        path: "/gas",
        icon: 'fa-ticket-alt',
        exact: true,
        settings_key: 'isGasReportDailyReport',
        child: gas_invoice
    },
    {
        id: 8,
        name: "General Ledger",
        title: "Hisably | General Ledger",
        path: "/ledger",
        icon: 'fa-university',
        exact: true,
        component: GeneralLedger,
        child: ledgers,
    },
    {
        id: 9,
        name: "Reports & Analytics",
        title: "Hisably | Reports & Analytics",
        path: "/reports",
        icon: 'fa-chart-bar',
        exact: true,
        component: BusinessReports,
        child: busineess_and_lottery_reports,
    },
    {
        id: 7,
        name: "Lottery Games",
        title: "Hisably | Lottery Games",
        path: "/lottery-games",
        icon: 'fa-ticket-alt',
        exact: true,
        permissions: ['lottery_games.page'],
        component: LotteryGames,
        parent_path: '/lottery',
    },
    {
        id: 11,
        name: "Weekly Settlement Form",
        title: "Hisably | Weekly Settlement Form",
        path: "/weekly/create.weekly.form",
        icon: 'fa-file-invoice',
        exact: true,
        permissions: ['lottery_games.page'],
        component: WeeklySettlementAdmin,
        parent_path: '/lottery',
    },
    {
        id: 11,
        name: "Activity Log",
        title: "Hisably | Activity Log",
        path: "/activity-log",
        icon: 'fa-life-ring',
        exact: true,
        permissions: ['lottery_games.page'],
        component: ActivityLog,
        parent_path: '/lottery',
    },
    {
        id: 11,
        name: "Support Log",
        title: "Hisably | Support Log",
        path: "/support-log",
        icon: 'fa-headset',
        exact: true,
        permissions: ['lottery_games.page'],
        component: SupportLog,
        parent_path: '/lottery',
    },
    {
        id: 12,
        name: "Move Store",
        title: "Hisably | Move Store",
        path: "/move-store",
        icon: 'fa-arrows-alt',
        exact: true,
        permissions: ['lottery_games.page'],
        component: MoveStore,
        parent_path: '/lottery',
    },
    {
        id: 13,
        name: "Store Trends Graph",
        title: "Hisably | Store Trends Graph",
        path: "/store-trends",
        icon: 'fa-chart-line',
        exact: true,
        permissions: ['lottery_games.page'],
        component: StoreGraph,
        parent_path: '/lottery',
    },
    {
        id: 14,
        name: "Store Transactions",
        title: "Hisably | Store Transactions",
        path: "/store-transactions",
        icon: 'fa fa-credit-card',
        exact: true,
        permissions: ['lottery_games.page'],
        component: Transactions,
        parent_path: '/lottery',
    },
    {
        id: 10,
        name: "Settings",
        title: "Hisably | Settings",
        path: "/settings",
        icon: 'fa-cogs',
        exact: true,
        child: settings
    },
    {
        id: 10,
        name: "Change Password",
        title: "Hisably | Change Password",
        path: "/change-password",
        icon: 'fa-cogs',
        exact: true,
        hidden: true,
        component: ChangePassword,
    },
    {
        id: 10,
        name: "Store Notes",
        title: "Hisably | Store Notes",
        path: "/StoreNotes/:storeID",
        icon: 'fa-cogs',
        exact: true,
        hidden: true,
        component: StoreNotes,
    },
]

export default routes

export const lottery_quick_links = [
    {
        id: 1,
        name: "Daily Report",
        title: "Hisably | Daily Report",
        path: "/daily_report",
        icon: 'fa-file',
        exact: true,
        component: DailyReport,
        permissions: ['daily_report.page'],
        parent_path: '/report',
    },
    {
        id: 2,
        name: "Lottery Inventory",
        title: "Hisably | Lottery Inventory",
        path: "/inventory",
        icon: 'fa-ticket-alt',
        component: LotteryInventory,
        exact: true,
        permissions: ['lottery_inventories.page'],
        parent_path: '/lottery',
    },
    {
        id: 3,
        name: "Instant Book Settlement",
        title: "Hisably | Instant Book Settlement",
        path: "/lottery-settlement-instant",
        icon: 'fa-ticket-alt',
        exact: true,
        permissions: ['instant_book_settlements.page'],
        component: InstantBookSettlement,
        parent_path: '/lottery',
    },
    {
        id: 4,
        name: "Online Settlement (weekly invoice)",
        title: "Hisably | Weekly Invoice Settlement",
        path: "/lottery-settlement",
        icon: 'fa-ticket-alt',
        exact: true,
        permissions: ['weekly_invoice_settlements.page'],
        component: LotterySettlement,
        parent_path: '/lottery',
    },
    {
        id: 5,
        name: "Lottery Analytics",
        title: "Hisably | Lottery Analytics",
        path: "/lottery-analytics",
        icon: 'fa-chart-bar',
        component: LotteryAnalytics,
        permissions: ['lottery_analytics.page'],
        exact: true,
        parent_path: '/reports',

    },
    {
        id: 6,
        name: "Book Movement",
        title: "Hisably | Book Movement",
        path: "/book-movement",
        icon: 'fa-ticket-alt',
        exact: true,
        permissions: ['book_movements.page'],
        component: BookMovement,
        parent_path: '/lottery',
    },
    // {
    //     id: 7,
    //     name: "Inventory History",
    //     title: "Hisably | Lottery Inventory",
    //     path: "/inventory",
    //     icon: 'fa-ticket-alt',
    //     component: LotteryInventory,
    //     exact: true,
    //     permissions: ['lottery_inventories.page'],
    //     parent_path: '/lottery',
    // },
];

export const create_quick_links = [
    {
        id: 1,
        name: "Daily Report",
        title: "Hisably | Daily Report",
        path: "/daily_report",
        icon: 'fa-file',
        exact: true,
        component: DailyReport,
        permissions: ['daily_report.page'],
        parent_path: '/report',
    },
    {
        id: 2,
        name: "Bank Deposit",
        title: "Hisably | Bank Deposit",
        path: "/bank-deposit",
        icon: 'fa-university',
        component: BankDeposits,
        permissions: ['bank_deposit.page'],
        exact: true,
        parent_path: '/ledger',
    },
    {
        id: 3,
        name: "ATM Deposit",
        title: "Hisably | ATM Deposit",
        path: "/atm",
        icon: 'fa-university',
        component: AtmDeposits,
        permissions: ['atm_deposit.page'],
        exact: true,
        parent_path: '/ledger',
    },
    {
        id: 4,
        name: "Sales Tax",
        title: "Hisably | Sales Tax",
        path: "/sales-tax",
        icon: 'fa-university',
        component: SalesTax,
        permissions: ['sales_tax.page'],
        exact: true,
        parent_path: '/ledger',
    },
    {
        id: 5,
        name: "Vendor",
        title: "Hisably | Store Vendor",
        path: "/store-vendor",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['store_vendors.page'],
        component: StoreVendor,
        parent_path: '/settings',
    },
    {
        id: 6,
        name: "Expense Type",
        title: "Hisably | Expense Type",
        path: "/store-expense-type",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['store_vendors.page'],
        component: ExpenseType,
        parent_path: '/settings',
    },
    {
        id: 7,
        name: "Users",
        title: "Hisably | Manage User",
        path: "/manage-user",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['store_users.page'],
        component: ManageUser,
        parent_path: '/settings',
    },
];

export const gas_quick_links = [
    {
        id: 1,
        name: "Daily Report",
        title: "Hisably | Daily Report",
        path: "/daily_report",
        icon: 'fa-file',
        exact: true,
        component: DailyReport,
        permissions: ['daily_report.page'],
        parent_path: '/report',
    },
    {
		id: 2,
		name: "Gas Report",
		title: "Hisably | Gas Daily Report",
		path: "/gas-report",
		icon: 'fa-chart-bar',
		component: GasReport,
		permissions: ['gas_differentials.page'],
        parent_path: '/reports',
	},
    {
		id: 3,
		name: "Gas Invoice",
		title: "Hisably | Gas Invoice",
		path: "/gas-invoice",
		icon: 'fa-gas-pump',
		exact: true,
		permissions: ['gas_invoices.page'],
		component: GasInvoice,
        parent_path: '/gas',
	},
    {
		id: 4,
		name: "Gas Tax Settings",
		title: "Hisably | Gas Tax Settings",
		path: "/gas-tax-settings",
		icon: 'fa-gas-pump',
		exact: true,
		permissions: ['gas_tax_settings.page'],
		component: GasTaxSettings,
        parent_path: '/gas',
	},
	{
		id: 5,
		name: "Gas Settings",
		title: "Hisably | Gas Settings",
		path: "/gas-settings",
		icon: 'fa-gas-pump',
		exact: true,
		permissions: ['gas_settings.page'],
		component: GasSettings,
        parent_path: '/gas',
	},
];

export const other_quick_links = [
    {
        id: 1,
        name: "Store Profile",
        title: "Hisably | Store Profile",
        path: "/store-profile",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['store_profile.page'],
        component: StoreProfile,
        parent_path: '/settings',
    },
    {
        id: 2,
        name: "Daily Report Settings",
        title: "Hisably | Store Settings",
        path: "/store-settings",
        icon: 'fa-cogs',
        exact: true,
        permissions: ['store_settings.page'],
        component: Settings,
        parent_path: '/settings',
    },
    {
        id: 3,
        name: "Business Analytics",
        title: "Hisably | Business Analytics",
        path: "/business-analytics",
        icon: 'fa-chart-bar',
        component: BusinessAnalytics,
        permissions: ['business_analytics.page'],
        exact: true,
        parent_path: '/reports',
    },
];

