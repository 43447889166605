import React, {useState, useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";

const DeleteModel = ({
                         children,
                         onYes,
                         onNo = setShow => setShow(false),
                         message = 'You will not be able to recover this data!',
                         yesLabel = 'Yes',
                         noLabel = 'Cancel',
                         customClose = false,
                         customButton = false,
                         onYesInventory = '',
                     }) => {

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const inventory = 'true';
    const showTrue = () => setShow(true)

    useEffect(() => {
        if (customClose)
            setShow(false)
    }, [customClose]);

    return <>
        <Modal show={show || loading} onHide={() => setShow(false)} centered className={customButton ? 'delete-inventory-modal':''}>
            <Modal.Body className="text-center">
                <div className="swal2-icon swal2-warning swal2-animate-warning-icon" style={{display: "flex"}}/>
                <h3>Are you sure?</h3>
                <p>{message}</p>
                <div className="model-action mt-4 mx-auto">
                    <Button variant="danger" disabled={loading}
                            onClick={() => onYes(setShow, setLoading)}>
                        {yesLabel}
                    </Button>
                    {
                        customButton &&
                        <Button variant="danger" className='custombtninventoryremove' disabled={loading}
                            onClick={() => onYesInventory(setShow, setLoading, inventory)}>
                            Delete & Remove From Inventory
                        </Button>
                    }
                    <Button variant="default ml-2" disabled={loading}
                            onClick={() => onNo(setShow, setLoading)}>{noLabel}</Button>
                </div>
            </Modal.Body>
        </Modal>
        {React.cloneElement(children, {onClick: showTrue})}
    </>;
};

export default DeleteModel;