import React from 'react';
import {Button} from "react-bootstrap";
import '../../../styles/finish-shift.scss'
import {connect} from "react-redux";
import DeleteModel from "../../custom/DeleteModel";
import Request from "../../../utils/Request";
import {responseValidate, formatDateViewToDB} from "../../../utils/functions";
import {api_version, app_timezone} from "../../../settings";
import store from "../../../store";
import {setNewToast, setShift} from "../../../store/reducers/App";
import {getStoreShift} from "../../../api/daily_report/StoreShift";
import Store from "../../../store";
import {formatToTimeZone} from "date-fns-timezone";

const FinishShift = ({shift, store_id, variant="primary", btn_title, setShowFinishButton, setAllScannedTickets, staticMode = false, onFinished = () => {}}) => {

    const finishShift = () => {
        // setLoading(true);
        Request.post(`${api_version}/core/stores/shift/finish/${shift?.id}`).then(responseValidate).then(result => {
            // setLoading(false);
            // setShow(false);
            if (result.status) {
                store.dispatch(setNewToast({
                    body: result.message,
                    variant: 'success'
                }))
                // const format = 'YYYY-M-D';
                // const full_date = formatToTimeZone(shift.date, format, {timeZone: app_timezone});
                const full_date = formatDateViewToDB(shift.date);
                getStoreShift(store_id, full_date, shift.type)
                    .then(response => responseValidate(response)).then(result => {
                    if (result.status && result.data !== null && result.data.shift !== undefined) {
                        
                        // hide Finish Scanning button near scan input
                        if(variant === 'info') {
                            setShowFinishButton(false)
                            setAllScannedTickets('')
                        }

                        Store.dispatch(setShift(
                            result.data.shift.id,
                            result.data.shift.date,
                            result.data.shift.shift,
                            result.data.shift.name,
                            result.data.shift.created_at,
                            result.data.daily_report,
                            result.data.lotto_vending,
                            result.data.daily_scan,
                            result.data.daily_gas,
                            result.data.cash_drops,
                            result.data.available_shifts,
                            result.data.returned_books,
                            result.data.activated_books,
                            result.data.transactions,
                            result.data.other_transactions,
                            result.data.daily_custom_fields,
                        ));
                    } else {
                        // setShow(false);
                        // setLoading(false);
                    }
                });
                onFinished();
            }
            else
                store.dispatch(setNewToast({
                    body: result.message,
                    variant: 'danger'
                }))
        })
    }

    // return shift && <DeleteModel onYes={finishShift} message="You want to finish this shift?" yesLabel="Yes, Finish!">
    //     <Button variant="primary" className={!staticMode ? 'finish-shift-button rounded' : ''}>
    //     Save &amp; Close <i className="fas fa-sign-out-alt"/>
    //     </Button>
    // </DeleteModel>;

    return shift &&
        <Button variant={variant} className={!staticMode ? 'finish-shift-button rounded m-3' : (variant === 'info' ? 'm-3' : 'mb-2 ml-3')} onClick={finishShift}>
        {btn_title} <i className="fas fa-sign-out-alt"/></Button>;
};

const mapStateToProps = state => ({
    shift: state.App.shift,
    store_id: state.App.store?.id
})

export default connect(mapStateToProps)(FinishShift);