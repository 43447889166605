import React from "react";
import Logo from './../../../assets/images/logo-main.png'
import Image from 'react-bootstrap/Image';

export default function SplashScreen() {
    const hisably_logo_loader = "https://app.hisably.com/img/logo-loader.png";

    return <div style={{
        backgroundColor: "#000",
        position: "fixed",
        height: "100%",
        width: "100%",
        zIndex: '9999'
    }}>
        <img
            style={
                {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginLeft: "-75px",
                    marginTop: "-15px",
                    height: '30px',
                    width: '150px'
                }
            }
            src={Logo}
            alt="Hisably Logo"/>
    </div>

    // return <div style={{
    //     position: 'fixed',
    //     top: '0',
    //     left: '0',
    //     bottom: '0',
    //     width: '100%',
    //     height: '100%',
    //     zIndex: '10000',
    //     backgroundColor: 'white',
    //     opacity: '0.8',
    // }}>
    //     <Image 
    //         src={hisably_logo_loader}
    //         className="ajax-loader"
    //     />
    // </div>;
}
