import React, {useEffect, useState} from "react";
import * as moment from "moment";
import {permissionTypes, super_admin, transactionTypes} from "../settings";
import {isArray} from "rxjs/internal-compatibility";

export const responseValidate = response => {
    if (response !== undefined && response.status === 200) {
        if (response.data.status) {
            return {
                status: true,
                message: response.data.message ? response.data.message : "Success",
                data: response.data.data
            }
        } else return {
            status: false,
            message: response.data.message ? response.data.message : "There Was An Error Please Try After Some Time.",
        }
    } else return {
        status: false,
        message: "There Was An Error Please Try After Some Time.",
    }
};

export const check = (required, has) => {
    if (required instanceof Array && required.length > 0 && has instanceof Array && has.length > 0) {
        return has.some(permission => {
            return required.includes(permission);
        });
    } else {
        return false;
    }
};

export const hasAnyChildPermission = (childes, permissions, roles) => {
    if (check([super_admin], roles))
        return true;
    let status = false;
    childes.map(child => {
        if (child.permissions === undefined ? true : check(child.permissions, permissions))
            status = true;
    });
    return status;
};

export const ordinal_suffix_of = i => {
    const j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
};

// export const sortTablelottery = () => {
//     (".lottery_scan_history_table tbody tr").sort(function (a, b){
//         return ("td", b).attr("class") < ("td", a).attr("class") ? 1 : -1;
//     }).appendTo('.lottery_scan_history_table tbody');
//     (".lottery_scan_history_table tbody tr").sort(function (a, b){
//         return ("td", b).attr("class") < ("td", a).attr("class") ? 1 : -1;
//     }).appendTo('.lottery_scan_history_table tbody');
// }

export const isToday = (dateParameter) => {
    const today = moment().format('MM/DD/YYYY');
    return dateParameter === today;
};

export const moveItem = (array, from, to) => {
    const f = array.splice(from, 1)[0];
    array.splice(to, 0, f);
};

export const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};

export const formatDate = (date_string) => {
    if (!date_string)
        return '-';
    const date = moment(date_string);
    return padZero(date.month() + 1) + '/' + padZero(date.date()) + '/' + date.year();
}

export const formatDateDB = (date_string) => {
    if (!date_string)
        return '-';
    const date = moment(date_string);
    return date.year() + '-' + padZero(date.month() + 1) + '-' + padZero(date.date());
}

export const formatDateDBToView = (date_string) => {
    if (date_string instanceof Object) {
        return date_string.format('MM/DD/YYYY');
    } else {
        if (date_string.indexOf('-') != -1) {
            const date = date_string.split('-');
            return padZero(date[1]) + '/' + padZero(date[2]) + '/' + date[0];
        } else {
            return date_string;
        }
    }
}

export const formatDateViewToDB = (date_string) => {
    if (date_string instanceof Object) {
        return date_string.format('YYYY-MM-DD');
    } else {
        if (date_string.indexOf('/') != -1) {
            const date = date_string.split('/');
            return date[2] + '-' + padZero(date[0]) + '-' + padZero(date[1]);
        } else if (date_string.indexOf('-') != -1) {
            return date_string.replace('00:00:00', '').trim();
        } else {
            return date_string;
        }   
    }
}

export const formatTime = (timestamp) => {
    if (!timestamp)
        return '-';
    const date = moment(timestamp);
    return padZero(date.hours()) + ':' + padZero(date.minutes());
}

export const formatMonthDate = (date_string) => {
    if (!date_string)
        return '-';
    const date = moment(date_string);
    return monthList[date.month()]['label'] + '-' + date.year();
}

export const undefinedToZeroOrValue = value => value !== undefined ? value : 0;

export const zeroToUndefinedOrValue = value => value !== 0 ? value : "";

export const unSignNumber = value => Math.abs(Number(value));

export const printDecimal = (value, digits = 2) => Number(undefinedToZeroOrValue(value)).toFixed(digits);

export const removeComma = (value) => value && value.indexOf(',') != -1 ? removeComma(value.replace(',', '')) : value;

export const printVolume = (value, digits = 3) => Number(undefinedToZeroOrValue(value)).toFixed(digits);

export const padZero = (number, count = 2) => number.toString().padStart(count, "0")

export const setArray = (data) => isArray(data) ? data : [];

export const permissionType = (permission) => {
    if (permission !== undefined) {
        const type = permission.split('.');
        if (type[1] !== undefined) {
            switch (type[1]) {
                case permissionTypes.readAll.key: {
                    return permissionTypes.readAll
                }
                case permissionTypes.readOne.key: {
                    return permissionTypes.readOne
                }
                case permissionTypes.create.key: {
                    return permissionTypes.create
                }
                case permissionTypes.update.key: {
                    return permissionTypes.update
                }
                case permissionTypes.delete.key: {
                    return permissionTypes.delete
                }
                default:
                    return permissionTypes.custom;
            }
        }
    }

    return permissionTypes.custom;
}

export const transactionModelToType = model_string => transactionTypes.find(row => row.key === model_string);

export const addDaysInDate = (date_string, days) => {
    const date = new Date(date_string);
    const new_date = new Date(date);
    new_date.setDate(new_date.getDate() + days);
    return (new_date.getMonth() + 1) + '/' + new_date.getDate() + '/' + new_date.getFullYear();
}

export const isMobileOrTablet = () => ( window.innerWidth <= 768 ) && ( window.innerHeight <= 1024 )

export const cashTypeList = [
    {
        label: 'Business Cash',
        value: 'business',
    },
    {
        label: 'Lottery Cash',
        value: 'lottery',
    },
    {
        label: 'Gas Cash',
        value: 'gas',
    }
];

export const monthListShort = [
    {
        label: 'Jan',
        value: 1,
    },
    {
        label: 'Feb',
        value: 2,
    },
    {
        label: 'March',
        value: 3,
    },
    {
        label: 'April',
        value: 4,
    },
    {
        label: 'May',
        value: 5,
    },
    {
        label: 'June',
        value: 6,
    },
    {
        label: 'July',
        value: 7,
    },
    {
        label: 'Aug',
        value: 8,
    },
    {
        label: 'Sept',
        value: 9,
    },
    {
        label: 'Oct',
        value: 10,
    },
    {
        label: 'Nov',
        value: 11,
    },
    {
        label: 'Dec',
        value: 12,
    }
];

export const monthList = [
    {
        label: 'January',
        value: '1',
    },
    {
        label: 'February',
        value: '2',
    },
    {
        label: 'March',
        value: '3',
    },
    {
        label: 'April',
        value: '4',
    },
    {
        label: 'May',
        value: '5',
    },
    {
        label: 'June',
        value: '6',
    },
    {
        label: 'July',
        value: '7',
    },
    {
        label: 'August',
        value: '8',
    },
    {
        label: 'September',
        value: '9',
    },
    {
        label: 'October',
        value: '10',
    },
    {
        label: 'November',
        value: '11',
    },
    {
        label: 'December',
        value: '12',
    }
];

export const getValByKey = (val, dataList) => {
    var name = dataList.map((data) => (data.value == val) && data.label);
    var filtered = name.filter(function (el) {
        return el != false;
    });
    return filtered;
}

export const getIndexByVal = (val, dataList) => {
    var name = dataList.map((data) => (data.value === val) && data.label);
    var filtered = name.filter(function (el) {
        return el != false;
    });
    return filtered;
}

export const useOnScreen = ref => {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    )

    useEffect(() => {
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [])

    return isIntersecting
};

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const setCurrancy = (amount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
    }).format(amount);
}

export const getDefaultBank = (settings, banks) => {
    if (settings?.storeDefaultBank?.value != '0') {
        const bank = banks.filter(bank => bank.id == settings?.storeDefaultBank?.value);
        return bank[0] ?? '';
    } else return '';
}

export const invoiceTypeFilter = [
    {
        label: 'Due Invoices',
        value: 'due_invoices',
    },
    {
        label: 'Unpaid Invoices',
        value: 'unpaid_invoices',
    },
    {
        label: 'Paid Invoices',
        value: 'paid_invoices',
    }
];

export const yearList = (startYear = 2022 , setLabel= false) => {
    const currentYear = new Date().getFullYear();
    const years = [];
    const label = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
      label.push({
        label: year.toString(),
        value: year.toString()
        });
    }
    if(setLabel){
        return label;  
        
    }
//   console.log(years);
    return years;
};