import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Button, Form, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import store from "../../store";
import {setNewToast} from "../../store/reducers/App";
import GlobalToast from "../../modules/Other/GlobalToast";
import {userResetPassword} from "../../store/reducers/Auth";
import HisablyLogoWhite from "../../assets/images/logo-main.png";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

const ResetPassword = () => {

    // API process
    const [process, setProcess]         = useState(false);
    const [isRedirect, setIsRedirect]   = useState(false);
    const lastSegment                   = window.location.href.split("/").pop();
    const url                           = window.location.href.split("/");

    const loginRedirect = () => {
        setIsRedirect(true);
    }

    useEffect(() => {
        if (url[url.length - 2] != "reset-password" || lastSegment == "") {
            loginRedirect();
        }
    }, [])

    // Inputs
    let emailInput              = useRef();
    let tokenInput              = useRef();
    let passwordInput           = useRef();
    let passwordConfirmInput    = useRef();

    useEffect(() => {
        setTimeout(() => emailInput?.focus(), 1000)
    }, [])

    const resetPassword = async () => {
        setProcess(true);
        const result = await userResetPassword(emailInput.value, tokenInput.value, passwordInput.value, passwordConfirmInput.value);

        if (result.status) {
            setProcess(false);
            store.dispatch(setNewToast({
                title: 'Success!',
                body: result.message,
                variant: 'success',
                autoHide: 5000
            }))

            setTimeout(() => loginRedirect(), 1000)
        } else {
            store.dispatch(setNewToast({
                title: 'Warning...!',
                body: result.message,
                variant: 'warning',
                autoHide: 5000
            }))
            setProcess(false);
        }
    }

    if(isRedirect)
        return <Redirect to={{pathname: "/login"}}/> 

    return <div className="hold-transition login-page">
        <GlobalToast/>
        <div className="login-container">
            <div className="login-card">
                <div className="login-left-content">
                    <div className="login-top-content">
                        <h2 className="mb-3">Reset Password</h2>
                        <p>Reset your password to access Daily Reports, Invoices and Payments, Payroll and Lottery Inventory</p>
                    </div>
                    <div className="login-bottom-content">
                        <img src={HisablyLogoWhite}/>
                    </div>
                </div>
                <div className="login-right-content">
                    <div className="login-top-content">
                        <Form onSubmit={async e => {
                            e.preventDefault();
                            await resetPassword()
                        }}>
                            <Form.Group controlId="formBasicToken">
                                <Form.Control type="hidden" ref={ref => {
                                    tokenInput = ref
                                }} placeholder="Email" value={lastSegment} />

                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control type="email" ref={ref => {
                                    emailInput = ref
                                }} placeholder="Email"/>

                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Control type="password" ref={ref => {
                                    passwordInput = ref
                                }} placeholder="Password"/>

                            </Form.Group>
                            <Form.Group controlId="formBasicPasswordConfirmation">
                                <Form.Control type="password" ref={ref => {
                                    passwordConfirmInput = ref
                                }} placeholder="Confirm Password"/>

                            </Form.Group>
                            <Button disabled={process} type="submit" variant="primary" block>
                                {
                                    !process && 'Reset Password'
                                }
                                {
                                    process && <Spinner size="sm" animation="grow"/>
                                }
                            </Button>
                        </Form>
                        <Link to="/login" className="mt-3 text-center d-block"> Back to login </Link>
                    </div>
                    <div className="login-bottom-content">
                        <Link to="/register" className="mt-5 text-center d-block"> New to Hisably? Create an
                            account </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    // user: state.Auth.user,
})

export default connect(mapStateToProps)(ResetPassword)