import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Button, Form, Spinner} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import store from "../../store";
import {setNewToast} from "../../store/reducers/App";
import GlobalToast from "../../modules/Other/GlobalToast";
import {userLogin} from "../../store/reducers/Auth";
import HisablyLogoWhite from "../../assets/images/logo-main.png";
import Nav from 'react-bootstrap/Nav';
import moment from "moment";

const Login = ({user}) => {

    const history = useHistory();
    // console.log(history);
    // API process
    const [process, setProcess] = useState(false);

    // Inputs
    let emailInput = useRef();
    let passwordInput = useRef();

    useEffect(() => {
        if (user !== null && (user.stores instanceof Array && user.stores.length === 0)) {
            store.dispatch(setNewToast({
                title: 'Unauthorized Access!',
                body: 'No Stores Are Assigned To Your Account',
                variant: 'warning',
                autoHide: 5000
            }))
        }
    }, [user])

    useEffect(() => {
        setTimeout(() => emailInput?.focus(), 1000)
    }, [])

    const login = async () => {
        setProcess(true);
        const result = await userLogin(emailInput.value, passwordInput.value);
        if (result.status) {
            if (result.data.roles[0] === 'Super Admin') {
                window.location.href = '/';
            } else {
                if (result.data !== null && (result.data.stores instanceof Array && result.data.stores.length === 0)) {
                    setProcess(false);
                    store.dispatch(setNewToast({
                        title: 'Unauthorized Access!',
                        body: 'No Stores Are Assigned To Your Account',
                        variant: 'warning',
                        autoHide: 5000
                    }))
                } else {
                    if (history.length > 2 && history.location !== undefined && history.location.from !== undefined && history.location.from.pathname !== undefined) {
                        window.location.href = history.location.from.pathname;
                    }else if (result?.data?.redirect_step_report) {
                        window.location.href = '/report/step-report';
                    } else {
                        window.location.href = '/report/daily_report';
                    }
                }
            }
        } else {
            store.dispatch(setNewToast({
                title: 'Warning...!',
                body: result.message,
                variant: 'warning',
                autoHide: 5000
            }))
            setProcess(false);
        }
    }

    return <div className="hold-transition login-page">
        {/* <div className="login-box">
            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                    <img src={HisablyLogo} />
                </div>
                <div className="card-body">
                    <p className="login-box-msg">Sign in to start your session</p>

                    <Form onSubmit={async e => {
                        e.preventDefault();
                        await login()
                    }}>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="fas-fa-envelope">
                                    <span className="fas fa-envelope"/>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="text"
                                ref={ref => {
                                    emailInput = ref
                                }}
                                placeholder="Email Address"/>
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="fas-fa-lock">
                                    <span className="fas fa-lock"/>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="password"
                                ref={ref => {
                                    passwordInput = ref
                                }}
                                placeholder="Password"/>
                        </InputGroup>

                        <Row>
                            <Col sm={12}>
                                <Button disabled={process} type="submit" variant="primary" block>
                                    {
                                        !process && 'Sign In'
                                    }
                                    {
                                        process && <Spinner animation="grow"/>
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </Form> */}

        {/*<p className="mb-1 mt-3">*/}
        {/*    <Link to={"/"}>I forgot my password</Link>*/}
        {/*</p>*/}
        {/*<p className="mb-0">*/}
        {/*    <Link to={"/"}>Register a new membership</Link>*/}
        {/*</p>*/}
        {/* </div>
            </div>
        </div> */}
        <GlobalToast/>
        <div className="login-container">
            <div className="login-card">
                <div className="login-left-content">
                    <div className="login-top-content">
                        <h2 className="mb-3">Login</h2>
                        <p>Get access to Daily Reports, Invoices and Payments, Payroll and Lottery Inventory</p>
                    </div>
                    <div className="login-bottom-content">
                        <img src={HisablyLogoWhite}/>
                        <div className="row space_under_logo">
                            <small><span className="ml-2">© 2016 - {moment().format('YYYY')} Hisably.</span></small>
                        </div>
                    </div>
                </div>
                <div className="mob-login-logo">
                    <img src={HisablyLogoWhite}/>
                </div>
                <div className="login-right-content">
                    <div className="login-top-content">
                        <Form onSubmit={async e => {
                            e.preventDefault();
                            await login()
                        }}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control type="email" ref={ref => {
                                    emailInput = ref
                                }} placeholder="Email"/>

                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Control type="password" ref={ref => {
                                    passwordInput = ref
                                }} placeholder="Password"/>
                            </Form.Group>
                            <Button disabled={process} type="submit" variant="primary" block>
                                {
                                    !process && 'Login'
                                }
                                {
                                    process && <Spinner size="sm" animation="grow"/>
                                }
                            </Button>
                        </Form>
                        <Link to="/forgot-password" className="mt-3 text-center d-block"> Forgot Password? </Link>
                    </div>
                    <div className="login-bottom-content">
                        <Link to="/register" className="mt-5 text-center d-block"> New to Hisably? Create an
                            account </Link>

                        <div className="mt-3 p-1 login-footer-menu text-center">
                            <Nav as="ul">
                                <Nav.Item as="li" key={1}>
                                    <a target="_blank" href="https://hisably.com/terms-and-conditions/#1497949114021-10f28a73-6446"> Privacy Policy </a>
                                </Nav.Item>
                                <Nav.Item as="li" key={2}>
                                    <a target="_blank" href="https://hisably.com/terms-and-conditions/"> Terms and Conditions </a>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="p-1 login-footer-menu text-center">
                            <Nav as="ul">
                                <Nav.Item as="li" key={3}>
                                    <a target="_blank" href="https://hisably.com/contact/"> Contact Us </a>
                                </Nav.Item>
                                <Nav.Item as="li" key={4}>
                                    <a target="_blank" href="https://hisably.com/support"> Help </a>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    user: state.Auth.user,
})

export default connect(mapStateToProps)(Login)